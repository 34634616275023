<template>
  <div class="tops">
    <template v-for="(category, key) in categories">
      <template v-if="key + key < categories.length">
        <div :key="key + 200" class="tops-container">
          <template v-if="key + key < categories.length">
            <div class="tops-grid margin-rigth-top">
              <Menu
                :key="category.id"
                :logo="logo"
                :header="headerStyles"
                :bluer="key === 0 ? categories[key].bluer : categories[key + key].bluer"
                :header2="header2Styles"
                :is-premium-user="getPremiumAccount"
                :interiors="headerinteriors"
                :categories="categories[key].categories"
                :category="key === 0 ? categories[key] : categories[key + key]"
                :season="getSeasonSelected || menuSeason"
                :position="key"
                :is-cup="isCup"
              />
            </div>
          </template>
          <template v-if="key + key + 1 < categories.length">
            <div class="tops-grid margin-left-top">
              <Menu
                :key="category.id"
                :logo="logo"
                :header="headerStyles"
                :is-premium-user="getPremiumAccount"
                :bluer="key === 0 ? categories[key + 1].bluer : categories[key + key + 1].bluer"
                :header2="header2Styles"
                :interiors="headerinteriors"
                :categories="categories[key].categories"
                :category="key === 0 ? categories[key + 1] : categories[key + key + 1]"
                :season="getSeasonSelected || menuSeason"
                :position="key"
                :is-cup="isCup"
              />
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    Menu: () => import('@/components/Sections/Top/Menu'),
  },
  props: {
    season: {
      type: Number,
      default: 1401,
    },
    custom: {
      type: Object,
      default: null,
    },
    /**
     * Indicates if must await for the season prop, or not to get tops data,
     * if not, tournaments will be fetched to get a default season
     * {boolean} awaitForSeasons
     */
    awaitForSeason: {
      type: Boolean,
      default: true,
    },
    isCup: {
      type: Boolean,
      default: false,
    },
    showLogos: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      arrayCategories: [
        { id: 1, name: 'Goles', bluer: false },
        { id: 2, name: 'Pases acertados', bluer: false },
        { id: 23, name: 'Tiros de Esquina', bluer: true },
        { id: 3, name: 'Balones recuperados en disputa', bluer: true },
        { id: 4, name: 'Asistencias', bluer: true },
        { id: 5, name: 'Participaciones en gol', bluer: true },
        { id: 6, name: 'Tiros a gol', bluer: true },
        { id: 7, name: 'Tiros a gol que llegaron a la portería', bluer: true },
        { id: 8, name: 'Cabeceos en área rival', bluer: true },
        { id: 9, name: 'Cabeceos en área propia', bluer: true },
        { id: 10, name: 'Centros', bluer: true },
        { id: 11, name: 'Centros acertados', bluer: true },
        { id: 12, name: '1vs1 exitosos ofensivo', bluer: true },
        { id: 13, name: '1vs1 exitosos ofensivo que terminaron en gol', bluer: true },
        { id: 14, name: '1vs1 defensivos exitoso', bluer: true },
        { id: 15, name: 'Rechaces', bluer: true },
        { id: 16, name: 'Balones ganados en área propia', bluer: true },
        { id: 17, name: 'Balones ganados en área rival', bluer: true },
        { id: 18, name: 'Faltas cometidas', bluer: true },
        { id: 19, name: 'Faltas recibidas', bluer: true },
        { id: 20, name: 'Tarjetas amarillas', bluer: true },
        { id: 21, name: 'Tarjetas rojas', bluer: true },
        { id: 22, name: 'Atajadas', bluer: true },
      ],
      menuSeason: 0,
      tournamentsUrl: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
    };
  },
  watch: {
    season(newValue) {
      if (newValue) {
        this.menuSeason = newValue;
      }
    },
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapGetters('loginGeneral', ['getPremiumAccount']),
    categories() {
      let response = [];
      if (this.$route.query.categories) {
        let queryCategories = this.$route.query.categories.split(',');
        let numCategories = queryCategories.map(Number);
        response = this.arrayCategories.filter(function(e) {
          return this.indexOf(e.id) >= 0;
        }, numCategories);
      } else {
        if (this.custom && this.custom.categories) {
          if (Object.keys(this.custom).length > 0) {
            if (this.custom.categories === 'all') {
              response = this.arrayCategories;
            } else {
              let queryCategoriesCustom = this.custom.categories.split(',');
              let numCategoriesCustom = queryCategoriesCustom.map(Number);
              response = this.arrayCategories.filter(function(e) {
                return this.indexOf(e.id) >= 0;
              }, numCategoriesCustom);
            }
          } else {
            response = this.arrayCategories;
          }
        } else {
          response = this.arrayCategories;
        }
      }
      return response;
    },
    logo() {
      if (this.$route.name === 'topsFull') {
        return 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png';
      } else if (this.$route.query.logo) {
        return this.$route.query.logo;
      } else {
        if (this.custom != null) {
          if (Object.keys(this.custom).length) {
            return this.custom.logo;
          } else {
            return 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png';
          }
        } else {
          return 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png';
        }
      }
    },
    headerinteriors() {
      if (this.custom != null) {
        if (Object.keys(this.custom).length) {
          return {
            color: this.custom.colorheader,
            background: this.custom.subHeaderBackground,
          };
        } else {
          return {
            color: this.$route.query.colorheader || '#132839',
            background: this.$route.query.backgroundheader || '#cce8b5',
          };
        }
      } else {
        return {
          color: this.$route.query.colorheader || '#132839',
          background: this.$route.query.backgroundheader || '#cce8b5',
        };
      }
    },
    headerStyles() {
      if (this.custom != null) {
        if (Object.keys(this.custom).length) {
          return {
            color: this.custom.headerColor,
            background: this.custom.headerBackground,
            borderColor: this.custom.headerBorderColor,
          };
        } else {
          return {
            color: this.$route.query.headerColor || '#fff',
            background: this.$route.query.headerBackground || '#242424',
            borderColor: this.$route.query.headerBorderColor || '#88da69',
          };
        }
      } else {
        return {
          color: this.$route.query.headerColor || '#fff',
          background: this.$route.query.headerBackground || '#242424',
          borderColor: this.$route.query.headerBorderColor || '#88da69',
        };
      }
    },
    header2Styles() {
      if (this.custom != null) {
        if (Object.keys(this.custom).length) {
          return {
            color: this.custom.header2Color,
            background: this.custom.header2Background,
          };
        } else {
          return {
            color: this.$route.query.header2Color || '#fff',
            background: this.$route.query.header2Background || '#3b3b3b',
          };
        }
      } else {
        return {
          color: this.$route.query.header2Color || '#fff',
          background: this.$route.query.header2Background || '#3b3b3b',
        };
      }
    },
  },
  methods: {
    showOrHiddenTops() {
      this.show = !this.show;
    },
    /**
     * @author Alejandro Martinez
     * @param {number} retries
     * @return {Promise<any|undefined>}
     */
    async fetchTournaments(retries) {
      try {
        const { data } = await axios.get(this.tournamentsUrl);
        return data;
      } catch (e) {
        if (retries > 0) {
          return this.fetchTournaments(retries - 1);
        }
        throw new Error(e);
      }
    },
  },
  async created() {
    try {
      if (!this.awaitForSeason) {
        const tournaments = await this.fetchTournaments(3);
        const mxLeague = tournaments.find(tournament => tournament._id === 1);
        const regularSeason = mxLeague.seasons[0];
        this.menuSeason = regularSeason.id;
      } else {
        this.menuSeason = this.season;
      }
    } catch (e) {
      throw new Error(e);
    }
  },
};
</script>
<style lang="scss">
html > body {
  padding-top: 0 !important;
}

.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scoped>
.hidden-top {
  display: none !important;
}
.div-more-tops {
  width: 100%;
  height: 80px;
  // background: green;
  margin-top: 30px;
  &__container-data:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &__container-data {
    width: auto;
    height: 80px;
    // background: orange;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Avenir-Demi';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 80px;
    letter-spacing: -0.13px;
    color: #3e3e3e;
    @media screen and (max-width: 500px) {
      width: 400px;
    }
    @media screen and (max-width: 400px) {
      width: 300px;
      font-size: 19px;
    }
    span {
      // background: blue;
      @media screen and (max-width: 500px) {
        margin-left: 35px;
      }
      @media screen and (max-width: 400px) {
        margin-left: 18px;
      }
    }
    img {
      width: 40px;
      margin-left: 15px;
    }
  }
}
.tops {
  overflow-x: hidden;
  padding-bottom: 30px;
}
.margin-rigth-top {
  margin-right: 10px !important;
  @media screen and (max-width: 1080px) {
    margin-right: 0px !important;
  }
}
.margin-left-top {
  margin-left: 10px !important;
  @media screen and (max-width: 1080px) {
    margin-left: 0px !important;
  }
}
.tops-container {
  width: 100%;
  height: 100%;
  margin: 0px !important;
  padding: 0px 0px !important;
  display: inline-flex;
  justify-content: center;
}
.tops-grid {
  width: 638px;
  display: inline-flex;
  height: 680px;
  margin: 30px 0px 30px 0px;
}
@media screen and (max-width: 1080px) {
  .tops-container {
    display: inline-block;
  }
  .tops-grid {
    width: 100%;
    height: 650px;
    margin: 35px 0px 35px 0px;
  }
}
@media screen and (max-width: 500px) {
  .tops-grid {
    height: 715px;
    margin: 25px 0px 25px 0px;
  }
}
</style>
